@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

/* Mobile optimizations */
@layer base {
    html {
        scroll-behavior: smooth;
        -webkit-tap-highlight-color: transparent;
        touch-action: manipulation;
        overflow-x: hidden;
    }

    body {
        /* Prevent pull-to-refresh on mobile */
        overscroll-behavior-y: none;
        /* Prevent content shift on mobile when address bar appears/disappears */
        min-height: -webkit-fill-available;
    }

    /* Better tap targets for mobile */
    button,
    a,
    [role="button"] {
        @apply select-none;
        /* Larger touch target without changing visual size */
        position: relative;
    }

    button::after,
    a::after,
    [role="button"]::after {
        content: "";
        position: absolute;
        inset: -8px;
        z-index: -1;
    }

    /* Faster transitions on mobile for better performance */
    @media (max-width: 768px) {

        .transition-all,
        .transition-transform,
        .transition-opacity,
        .transition {
            transition-duration: 0.2s !important;
        }

        /* Optimize images for mobile */
        img {
            @apply transform-gpu;
        }
    }

    /* Enhance scrolling experience */
    ::-webkit-scrollbar {
        width: 6px;
    }

    ::-webkit-scrollbar-track {
        background: transparent;
    }

    ::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.2);
        border-radius: 3px;
    }
}

/* Improve focus states */
@layer utilities {
    .mobile-focus:active {
        @apply ring-2 ring-black/10 ring-offset-2 ring-offset-white;
    }
}